import Button from 'components/ui/base/button';
import Modal from 'components/ui/base/modal/Modal';
import { FC, useMemo } from 'react';
import { Exchange } from 'store/exchanges/exchange';
import {
    ContainerButtonDiv,
    ContainerH5Text,
    ContainerText,
    CurrencyText,
    DoApplyText,
    TitleText,
} from './ModalExchangeRating.styles';

interface Props {
    open: boolean;
    onClose: Function;
    currency?: Exchange;
}

const ModalExchangeRating: FC<Props> = ({ open, onClose, currency }) => {
    const currencyText = useMemo(() => {
        return `${currency?.currency.name}: ${currency?.rate}`;
    }, [currency]);

    return (
        <Modal
            title={
                <ContainerH5Text>
                    <TitleText>Exchange Rate</TitleText>
                </ContainerH5Text>
            }
            centered
            visible={open}
            onCancel={() => onClose()}
            width={553}
            footer={
                <ContainerButtonDiv>
                    <Button type="primary" onClick={() => onClose()} size="large">
                        OK
                    </Button>
                </ContainerButtonDiv>
            }
        >
            <ContainerButtonDiv style={{ marginBottom: '2.4rem' }}>
                <DoApplyText>{currency?.availableFrom && `Date of validity: ${currency?.availableFrom}`}</DoApplyText>
            </ContainerButtonDiv>
            <ContainerH5Text>
                <CurrencyText>{currencyText}</CurrencyText>
            </ContainerH5Text>
            <ContainerText>
                <DoApplyText>The exchange rate has been applied.</DoApplyText>
            </ContainerText>
        </Modal>
    );
};

export default ModalExchangeRating;
