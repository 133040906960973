import { useCallback, useMemo } from 'react';
import {
    doGetIrelandCodelists,
    doGetUkCodelists,
    doGetIeUnLocodeExtendedCodelist,
    doGetUkUnLocodeExtendedCodelist,
} from 'store/codelists/actions';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationTypes } from 'store/declarations/enums/common/declaration-types';
const useCodelists = () => {
    const dispatch = useAppDispatch();

    const irelandCodelists = useAppSelector((state) => state.codelists.irelandCodelists);
    const ukCodelists = useAppSelector((state) => state.codelists.ukCodelists);
    const ieUnloPagination = useAppSelector((state) => state.codelists.ieUnloPagination);
    const ukUnloPagination = useAppSelector((state) => state.codelists.ukUnloPagination);

    const aisCodelists = irelandCodelists?.ais;
    const cdsCodelists = ukCodelists?.cds;
    const aesCodelists = irelandCodelists?.aes;
    const nctsCodelists = irelandCodelists?.ncts;
    const ukNctsCodelists = ukCodelists?.ncts;

    const getIrelandCodelists = useCallback(
        (type: DeclarationTypes | undefined) => dispatch(doGetIrelandCodelists(type)),
        [dispatch]
    );
    const getUkCodelists = useCallback(
        (type: DeclarationTypes | undefined) => dispatch(doGetUkCodelists(type)),
        [dispatch]
    );

    const getIeUnLocodeExtendedCodelist = useCallback(
        (customerId: string | undefined, pagination: { page: number; size: number }) =>
            dispatch(doGetIeUnLocodeExtendedCodelist(customerId, pagination)),
        [dispatch]
    );

    const getUkUnLocodeExtendedCodelist = useCallback(
        (customerId: string | undefined, pagination: { page: number; size: number }) =>
            dispatch(doGetUkUnLocodeExtendedCodelist(customerId, pagination)),
        [dispatch]
    );

    const getCodeLists = useCallback(
        async (country: string | undefined, type: DeclarationTypes | undefined, customerId: string | undefined) => {
            if (!type || !customerId || !country) return [];

            let codelists: any = {};
            if (country === DeclarationCountry.IRELAND) {
                codelists = irelandCodelists;
            } else codelists = ukCodelists;

            const cachedCodelists = codelists?.[type.toLowerCase()];
            if (cachedCodelists) return cachedCodelists;

            try {
                if (country === DeclarationCountry.IRELAND) {
                    await getIrelandCodelists(type);
                    await getIeUnLocodeExtendedCodelist(customerId, { page: 0, size: 20 });
                } else {
                    await getUkCodelists(type);
                    await getUkUnLocodeExtendedCodelist(customerId, { page: 0, size: 20 });
                }
            } catch (error) {
                console.error(`Failed to fetch codelists for ${country} with type ${type}:`, error);
            }
        },
        [
            getIrelandCodelists,
            irelandCodelists,
            ukCodelists,
            getUkCodelists,
            getIeUnLocodeExtendedCodelist,
            getUkUnLocodeExtendedCodelist,
        ]
    );

    return {
        nctsCodelists,
        ukNctsCodelists,
        aisCodelists,
        cdsCodelists,
        getIrelandCodelists,
        getUkCodelists,
        aesCodelists,
        getCodeLists,
        getIeUnLocodeExtendedCodelist,
        getUkUnLocodeExtendedCodelist,
        ukUnloPagination,
        ieUnloPagination,
    };
};

export default useCodelists;
